import { createApp } from 'vue'
import Vuex from 'vuex'
import moduleContact from "@/store/Contact/moduleContact";
import App from "@/App";

createApp(App).use(Vuex)
export default new Vuex.Store({
    modules: {
        contact: moduleContact
    }
})

<template>
  <div class="grid grid-rows-5 h-screen">
<!--    <video preload="metadata" class="md:hidden row-stan-3 row-start-1" id="background-video" muted>-->
<!--      <source src="../assets/video/428-926(1).mp4#t=0.001" type="video/mp4">-->
<!--    </video>-->
    <div class="mobile md:hidden row-stan-3 row-start-1">
      <img class="bg-img" src="../assets/homeBackground/homebg.gif" alt="">
    </div>
    <video preload="metadata" class="hidden md:block lg:hidden row-stan-3 row-start-1" id="tablet-view" autoplay muted>
      <source class="md:block lg:hidden" src="../assets/homeBackground/744.1133.mp4#t=0.001" type="video/mp4">
    </video>
    <video preload="metadata" class="hidden lg:block row-stan-3 row-start-1" id="desktop-view" autoplay muted>
      <source class="hidden lg:block" src="../assets/homeBackground/1280.832.mp4#t=0.001" type="video/mp4">
    </video>
    <div class="row-start-2 lg:w-1/2">
      <div class="mx-10 md:mx-20">
<!--        <h1 class="text-5xl md:text-6xl text-left">BFB</h1>-->
<!--        <img class="w-28 md:w-36 lg:w-40" src="../assets/main-logo.png" alt="">-->
        <div id="logo" class="fading-content">
<!--          <svg class="svg-icon" width="160px" height="160px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox="20 -40 200.89 200.89" xml:space="preserve"><defs><path id="SVGID_1_" d="M0 0h189.89v189.89H0z"/></defs><clipPath id="SVGID_2_"><use xlink:href="#SVGID_1_" style="overflow:visible"/></clipPath><path class="st1" d="M111.01 102.65v14.59h24.81c8.35 0 12.48-3.81 12.48-10.13 0-6.16-4.7-10.46-13.37-10.46h-16.91v-6h16.34c7.46 0 11.19-4.05 11.19-9.24 0-4.95-3.4-8.76-10.94-8.76h-9.65v-6.08h10.7c11.27 0 16.86 6.32 16.86 14.27 0 6.48-3.57 10.94-9.65 12.56 7.13.89 12.4 6.41 12.4 14.02 0 9.97-7.05 15.89-18.72 15.89h-32.51v-20.67h6.97zM78.88 123.31V96.65H54.96c-8.67 0-13.38 4.29-13.38 10.46 0 6.32 4.13 10.13 12.48 10.13h17.83v6.08H53.34c-11.67 0-18.72-5.92-18.72-15.89 0-7.62 5.27-13.13 12.4-14.02-6.08-1.62-9.65-6.08-9.65-12.56 0-7.94 5.59-14.27 16.86-14.27h31.61v56.74h-6.96zm0-50.66H55.29c-7.54 0-10.94 3.81-10.94 8.76 0 5.19 3.73 9.24 11.19 9.24h23.34v-18z"/><path class="st2" d="M78.88 90.64h32.13v6H78.88zM78.88 66.57h39.14v6.08H78.88z"/></svg>-->
          <svg class="svg-icon lg:-mt-4" width="160px" height="160px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0" y="0" viewBox="0 0 189.89 189.89" style="enable-background:new 0 0 189.89 189.89" xml:space="preserve"><defs><path id="SVGID_1_" d="M0 0h189.89v189.89H0z"/></defs><clipPath id="SVGID_2_"><use xlink:href="#SVGID_1_" style="overflow:visible"/></clipPath><path d="M94.94 189.89c52.44 0 94.94-42.51 94.94-94.94C189.89 42.51 147.38 0 94.94 0S0 42.51 0 94.94c0 52.44 42.51 94.95 94.94 94.95" style="clip-path:url(#SVGID_2_);fill:#fff"/><path class="st1" d="M111.01 102.65v14.59h24.81c8.35 0 12.48-3.81 12.48-10.13 0-6.16-4.7-10.46-13.37-10.46h-16.91v-6h16.34c7.46 0 11.19-4.05 11.19-9.24 0-4.95-3.4-8.76-10.94-8.76h-9.65v-6.08h10.7c11.27 0 16.86 6.32 16.86 14.27 0 6.48-3.57 10.94-9.65 12.56 7.13.89 12.4 6.41 12.4 14.02 0 9.97-7.05 15.89-18.72 15.89h-32.51v-20.67h6.97zM78.88 123.31V96.65H54.96c-8.67 0-13.38 4.29-13.38 10.46 0 6.32 4.13 10.13 12.48 10.13h17.83v6.08H53.34c-11.67 0-18.72-5.92-18.72-15.89 0-7.62 5.27-13.13 12.4-14.02-6.08-1.62-9.65-6.08-9.65-12.56 0-7.94 5.59-14.27 16.86-14.27h31.61v56.74h-6.96zm0-50.66H55.29c-7.54 0-10.94 3.81-10.94 8.76 0 5.19 3.73 9.24 11.19 9.24h23.34v-18z"/><path class="st2" d="M78.88 90.64h32.13v6H78.88zM78.88 66.57h39.14v6.08H78.88z"/></svg>
        </div>
      </div>
      <div class="mx-10 md:mx-20 mt-5 lg:mt-14">
        <h1 id="header" class="title font-sculpin fading-content line font-regular text-lg md:text-xl lg:text-3xl text-left lg:whitespace-nowrap">Selfcare for the whole family, coming soon</h1>
      </div>
      <div class="mt-4">
        <p id="element-to-animate" class="font-modernEra fading-content mx-10 md:mx-20 lg:mr-2 2xl:mr-36 text-sm md:text-lg text-left">To be the first to experience Beauty for Boo, sign up here.</p>
      </div>
      <div id="input-element" class="fading-content mt-6 lg:mt-10">
        <div class="group">
          <div class="relative flex items-center button rounded-full mx-10 md:mx-20">
            <input v-model="email" id="8" name="email" type="text" placeholder="Email address" class="peer relative h-10 md:h-14 w-full rounded-full bg-gray-50 pl-4 pr-20 text-sm md:text-lg outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-white focus:drop-shadow-lg" />
            <button @click="submit" class="font-modernEra button right-0 h-10 md:h-14 w-20 md:w-24 lg:w-32 rounded-r-full text-sm md:text-lg text-theme-3 transition-all duration-200 ease-in-out">Sign up</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { useToast } from 'vue-toastification';

export default {
  name: "HomeComponent",
  components: {
  },
  data() {
    return {
      email: ""
    }
  },
  mounted() {
    setTimeout(() => {
      $(document).ready(function() {
        $("#element-to-animate").removeClass("fading-content");
        $("#element-to-animate").addClass("fading");
        $("#input-element").removeClass("fading-content");
        $("#input-element").addClass("fading");
        $("#header").removeClass("fading-content");
        $("#header").addClass("lineUp");
        $("#logo").removeClass("fading-content");
        $("#logo").addClass("lineUp");
        document.getElementById("background-video").play()
      });
    }, 3000)
  },
  methods: {
    submit() {
      const toast = useToast();
      const data = {
        email: this.email
      }
      // this.loading = true
      this.$store.dispatch("contact/contactSalesAction", data).then(() => {
        toast("Your information has been submitted!", {
          timeout: 5000,
          position: "top-right",
          hideProgressBar: true,
          type: "success",
          maxToasts: "1",
          closeButton: false,
          toastClassName: "my-custom-toast-class",
        });
        this.email = ""
        // this.loading = false
        // this.dialog = false
      })
    }
  }
}
</script>
<style scoped>
#background-video {
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
#tablet-view {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
#desktop-view {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
.button {
  background-color: #DFDBD6;
}
.fading-content {
  opacity: 0;
}
.fading {
  animation-name: fade-in !important;
  animation-duration: 4s;
}
.lineUp {
  animation: 2s anim-lineUp ease-out !important;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(-80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/*.title {*/
/*  font-family: sculpin, sans-serif;*/
/*  font-style: normal;*/
/*  font-weight: 400;*/
/*}*/
.svg-icon {
  width: 100px;
  height: 100px;
}
@media only screen and (min-width: 1024px) {
  .svg-icon {
    transform: scale(1.2);
    width: 130px;
    height: 130px;
  }
  #element-to-animate {
    line-height: normal;
  }
}
.bg-img {
  width: 100%;
  height: 100vh;
  object-fit: unset;
}
@media only screen and (max-width: 768px) {
  #element-to-animate {
    line-height: 17px;
  }
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import "../src/index.css"
import store from "@/store/store";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
window.$ = window.jQuery = require('jquery');


createApp(App).use(store).use(Toast).mount('#app')

<template>
  <div class="background-desk lottie-animation-container">
    <HomeComponent />
  </div>
</template>

<script>
import HomeComponent from "@/components/Home";
export default {
  name: 'App',
  components: {
    HomeComponent
  }
}
</script>

<style>
body {
  margin: 0 !important;
}
</style>
